import React from 'react';
import Banner from '../components/Banner';
import Title from '../components/Title';
function Heartofcollingwood() {
    return (
        <div>
            <Banner 
 title=""
 Subtitle="Located in the heart of Collingwood!"
 classss="amenities-banner"
 >
 
<p>Cafe culture and Smith Street shopping at your doorstep</p>

 </Banner>

        </div>
    )
}

export default Heartofcollingwood;
