
import React from 'react';
import Banner from '../components/Banner';
import Title from '../components/Title';
function Contact() {
    return (
        <section id="contact">
            <Banner 
    title=""
    Subtitle=""
    classss="amenities-banner"
    >
        <Title title="Contact Us" className="title"></Title>
    

        <div>
        
        <div className="space2"></div>
        <p className="space3">Email: hello@thenode.com.au</p>
        <p className="space3">Phone: 0459905599</p>
        </div>

        



    </Banner>

        </section>
              


    )
}

export default Contact
